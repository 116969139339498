export const COUNTRY_CAPITAL_COORDINATES = {
	de: { lat: 52.52, lng: 13.405 },
	es: { lat: 40.4168, lng: -3.7038 },
};

export const ZOOM_LEVEL = {
	veryLow: 15,
	low: 14,
	moderate: 12,
	high: 10,
	extreme: 7,
};

export const MAX_GMAPS_ZOOM = 21;
