import React from "react";

import { useTranslation } from "next-i18next";
import { useIntersection } from "react-use";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { BreakLines } from "@/components/break-lines";
import { Column, Grid, Hidden, Row } from "@/components/grid";
import { NewsletterSignup } from "@/components/newsletter-signup";
import { Typography } from "@/components/typography/typography";
import type { IconName } from "@/design-system/atoms/icons";
import { Icon } from "@/design-system/atoms/icons";
import { Accordion } from "@/design-system/molecules/accordion";
import { useFooterIntersectionContext } from "@/hooks/intersection";
import { TypographyVariant } from "@/theme";
import type {
	BusinessInfoCollection,
	NavigationCollection,
	NavigationItem,
	TextCollection,
} from "@/types/contentful-api";

import { Spacer } from "../components";

import {
	AccordionHeader,
	FooterCallEmailLink,
	FooterNavigationMenu,
	IconGrid,
	StyledFooter,
} from "./components";

interface FooterProps {
	data: {
		footerNavigation: NavigationCollection;
		businessInfoCollection: BusinessInfoCollection;
		textCollection: TextCollection;
	};
}

const KununuScore = () => {
	// Only for Germany
	return (
		<>
			<Spacer spacing="xxs" />
			<Link href="https://www.kununu.com/de/evernest1?utm_source=widget&utm_campaign=widget_selfservice_scorelarge">
				<a target="_blank" rel="nofollow">
					<Image
						unoptimized
						src="https://www.kununu.com/de/partner/KlVaD1RdVQ%3D%3D/self-service-button?button-type=3"
						alt="Evernest kununu Score"
						width="150"
						height="100"
						layout="fixed"
					/>
				</a>
			</Link>
		</>
	);
};

export const EvernestFooter: React.FC<FooterProps> = ({ data }) => {
	const { t } = useTranslation("navigation");
	const { locale } = useRouter();

	const ref = React.useRef<HTMLDivElement>();
	const intersection = useIntersection(ref, {
		root: null,
		rootMargin: "0px",
		threshold: 0,
	});
	const intersecting = React.useMemo(() => intersection?.isIntersecting, [intersection]);
	const { setIntersecting } = useFooterIntersectionContext();

	const [businessInfoItems] = data.businessInfoCollection.items ?? [];

	React.useEffect(() => {
		setIntersecting(intersecting);
	}, [setIntersecting, intersecting]); // Todo: Check the need to test `setIntersecting` dependency here

	return (
		<StyledFooter ref={ref}>
			<Grid overflow>
				<Row>
					{
						data.footerNavigation.items[0]?.itemsCollection.items
							.filter(Boolean)
							.map((navigationItem: NavigationItem) => {
								return (
									<Column key={navigationItem.sys.id} raw l={3}>
										<Hidden s m>
											<Accordion
												dark
												headerComponent={AccordionHeader}
												title={navigationItem.label}
												id={`${navigationItem?.__typename}${navigationItem.sys.id}`}
											>
												<FooterNavigationMenu
													aria-label={navigationItem.label}
													data={navigationItem.itemsCollection.items}
												/>
											</Accordion>
											<Spacer spacing="s" />
										</Hidden>
										<Hidden l>
											<Typography
												component="h4"
												variant={TypographyVariant.headlineSansXXS}
											>
												{navigationItem.label}
											</Typography>
											<FooterNavigationMenu
												aria-label={navigationItem.label}
												data={navigationItem.itemsCollection.items}
											/>
										</Hidden>
									</Column>
								);
								// This 'any' ignoring type issue where 'Row' expects explicit 'Column'
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
							}) as any
					}
					{businessInfoItems && (
						<Column raw l={3}>
							<Hidden s m>
								<Accordion
									dark
									headerComponent={AccordionHeader}
									title={t("navigation:address")}
									id="acc-address"
								>
									{businessInfoItems.headline && (
										<>
											<Typography tight>
												<BreakLines text={businessInfoItems.headline} />
											</Typography>
											<Spacer spacing="xs" />
										</>
									)}
									{businessInfoItems.email && (
										<Link passHref href={`mailto:${businessInfoItems.email}`}>
											<FooterCallEmailLink>
												{businessInfoItems.email}
											</FooterCallEmailLink>
										</Link>
									)}
									{businessInfoItems.footerCallLink &&
										businessInfoItems.footerPhoneNumber && (
											<Link
												passHref
												href={`tel:${businessInfoItems.footerCallLink}`}
											>
												<FooterCallEmailLink>
													{businessInfoItems.footerPhoneNumber}
												</FooterCallEmailLink>
											</Link>
										)}
								</Accordion>
								<Spacer spacing="s" />
							</Hidden>
							<Hidden l>
								<Typography
									component="h4"
									variant={TypographyVariant.headlineSansXXS}
								>
									{t("navigation:address")}
								</Typography>
								{businessInfoItems.headline && (
									<>
										<Typography tight>
											<BreakLines text={businessInfoItems.headline} />
										</Typography>
										<Spacer spacing="xs" />
									</>
								)}
								{businessInfoItems.email && (
									<Link passHref href={`mailto:${businessInfoItems.email}`}>
										<FooterCallEmailLink>
											{businessInfoItems.email}
										</FooterCallEmailLink>
									</Link>
								)}
								{businessInfoItems.footerCallLink &&
									businessInfoItems.footerPhoneNumber && (
										<Link
											passHref
											href={`tel:${businessInfoItems.footerCallLink}`}
										>
											<FooterCallEmailLink>
												{businessInfoItems.footerPhoneNumber}
											</FooterCallEmailLink>
										</Link>
									)}
								{locale === "de" && <KununuScore />}
							</Hidden>
						</Column>
					)}
					<Column raw l={3}>
						<Typography top component="h4" variant={TypographyVariant.headlineSansXXS}>
							{t("navigation:newsletter")}
						</Typography>
						<NewsletterSignup
							footer
							aria-label="footer-navigation-4"
							textCollection={data.textCollection}
						/>
						{businessInfoItems?.itemsCollection.items.length > 0 && (
							<IconGrid>
								{businessInfoItems?.itemsCollection.items
									.filter(Boolean) // Fixme: Do this in `getStaticProps` or check if it could be filtered out in GraphQL query
									.map(({ marketingLinkUrl, socialMedia, sys }) => (
										<Link key={sys.id} passHref href={marketingLinkUrl}>
											<a target="_blank" rel="noreferrer">
												<Icon icon={socialMedia as IconName} />
											</a>
										</Link>
									))}
							</IconGrid>
						)}
						{locale === "de" && (
							<Hidden s m>
								<KununuScore />
							</Hidden>
						)}
					</Column>
				</Row>
			</Grid>
		</StyledFooter>
	);
};
